.about-container {
  margin: 5vh 10vw;

}

.abouttext{
  font-size: 12px;
  width: 70vw;
  padding: 1em 0;
}


.info {
  padding: 1em 0;
}
