.header {
  width: 100%;
  height: 8%;
  display: flex;
  padding: 2% 10vw;
  background-color: #354f52;
}

.header-item {
  padding: 1em 1em 1em 0;
}


.header-nutral {
  background-color: #2f3e46;
}
