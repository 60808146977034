body {
  /* background-color: hsl(194, 85%, 21%); */
  background-color: #354f52;
  color: white;
  margin: 0;
  font-weight: 300;
  font-size: 1em;
  letter-spacing: 0.1em;
  line-height: 140%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



#linkstyle {
  text-decoration: none;
  color: white;
}


#linkstyle-italic {
    text-decoration: none;
    /* text-decoration: underline; */
    color: white;
    font-style: italic;
}


.calm-sketch-background {
  background-color: #343a40;
  display: flex;
  align-items: center;
  justify-content: center;

}
