.collection-bezier {
  display: flex;
  flex-wrap: wrap;
  width: 80vw;
  height: 80vh;
}


.collection-bezier-item {
  background: white;
  width: 30em;
  height: 30em;
  margin: 1em;
  padding: 0.3em;
}


.bezier-image {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-color: #354f52;
}


#bez1 {
  background-image: url('./images/bez1.png');
}

#bez2 {
  background-image: url('./images/bez2.png');
}

#bez3 {
  background-image: url('./images/bez3.png');
}

#bez4 {
  background-image: url('./images/bez4.png');
}

#bez5 {
  background-image: url('./images/bez5.png');
}

#bez6 {
  background-image: url('./images/bez6.png');
}

#bez7 {
  background-image: url('./images/bez7.png');
}

#bez8 {
  background-image: url('./images/bez8.png');
}
