.collection-thumernails {
  display: flex;
  flex-wrap: wrap;
  width: 80vw;
  height: 80vh;
}




.collection-thumernails-item {
  background: white;
  width: 200px;
  height: 200px;
  margin: 9px;
  padding: 4px;
}




.dragforce-image{
  width: 100%;
  height: 100%;
  background-size: contain;
  background-image: url('../codechallanges/dragforce/image_dragforce.png');
}


.radiusflow-image {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-image: url('../codechallanges/radiusflow/image_radiusflow.png');
}


.circlingaround-image {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #89b0ae;
  background-image: url('../codechallanges/circlingaround/image_circling_around.png');
}


.perlinnoiseflow-image {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #354f52;
  background-image: url('../codechallanges/perlinnoiseflow/images/flow1.png');
}


.bezier-image {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #354f52;
  background-image: url('../codechallanges/bezier/images/bez4.png');
}


.soundcircle-image {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #354f52;
  background-image: url('../codechallanges/soundcirle/images/sc1.png');
}
