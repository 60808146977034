.App {
  text-align: center;
  align-items: center;
  /* margin-top: 20vh; */

}

.maintitle-container {
  margin: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.maintitle  {
  color: #edf2f4;
  font-size: 76px;
  font-weight: 400;
  padding: 50px 0;
}


.subtitle {
  font-size: 1.5em;
}

.horizontal-line {
  width:  80%;
  border: 2px solid white
}
