
.collection-perlinnoiseflow {
  display: flex;
  flex-wrap: wrap;
  width: 80vw;
  height: 80vh;
}




.collection-perlinnoiseflow-item {
  background: white;
  width: 30em;
  height: 30em;
  margin: 1em;
  padding: 0.3em;
}

.collection-dummy-item {
    background: none;
    width: 30em;
    height: 30em;
}


.perlinnoiseflow-image {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-color: #2b2d42;
}





#imageflow1 {
  background-image: url('./images/flow1.png');
}

#imageflow2 {
  background-image: url('./images/flow2.png');
}

#imageflow3 {
  background-image: url('./images/flow3.png');
}

#imageflow4 {
  background-image: url('./images/flow4.png');
}
